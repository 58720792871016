/* Live stream display
*/

import React, { Component } from 'react'

import { connect } from 'react-redux'
import LiveStream from '../components/live_streams/LiveStream'
import orm from '../models/orm'
import { fetchStreamLinksIfNeeded } from '../actions/misc_streams'
import { setTheaterModeOn, setTheaterModeOff } from '../actions/compositions'
import { fetchLiveStreamsIfNeeded, MEDIA_TYPE_LIVE_STREAM } from '../actions/media_type_fetch'
import { flashMessage } from 'redux-flash'

class StreamFetcher extends Component {
  constructor() {
    super()
    this.state = {
     fetchedStreamLinks: false,
     fetchedStreams: false
    }
  }
  fetchStreams(props) {
    if(! props.liveStreamsFetched && ! this.state.fetchedStreams) {
      this.setState({ fetchedStreams: true })
      props.fetchStreamsIfNeeded()
    }
    else if (! props.linksNotNeeded && ! props.linksFetched && props.stream && ! this.state.fetchedStreamLinks) {
      this.setState({ fetchedStreamLinks: true })
      props.fetchStreamLinksIfNeeded(props.stream)
    }
  }
  componentDidMount() {
    this.fetchStreams(this.props)
  }
  componentWillReceiveProps(nextProps) {
    this.fetchStreams(nextProps)
  }

  render () {
    return <LiveStream {...this.props} />
  }
}

const mapStateToProps = (state, ownProps) => {
  let stream, linksFailure
  const fetchComplete = state.data.isCompleteCombined
  const liveStreamsFetched = state.data.fetchedMediaType[MEDIA_TYPE_LIVE_STREAM]
  const theaterMode = state.compositions.theaterMode
  if(liveStreamsFetched) {
    const { LiveStream, Person } = orm.session(state.data.orm)
    stream = LiveStream.findByIdentifier(ownProps.match.params.identifier)
    if(stream) {
      if(stream.program) {
        stream.program.composition_performances.forEach( (performance) => {
          if(performance.conductor) {
            performance.conductor_instance = Person.findById(performance.conductor)
          }
          performance.featured_artists_instances = performance.featured_artists.map( (artist) => ({person: Person.findById(artist.person), role: artist.roles}))
          performance.composer = Person.findById(performance.composition.composer)
        })
      }
      linksFailure = stream.fetched_streaming_links && stream.fetched_streaming_links !== 'success' ? stream.fetched_streaming_links : ''
    }
    else {
      linksFailure = true
    }
  }
  return {
    liveStreamsFetched: liveStreamsFetched,
    fetchComplete: fetchComplete,
    linksNotNeeded: ! stream || (stream.isComplete() && ! stream.can_replay),
    linksFetched: stream && stream.fetched_streaming_links === 'success',
    linksFailure: linksFailure,
    theaterMode: theaterMode,
    stream: stream
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchStreamLinksIfNeeded: (stream) => {
      dispatch(fetchStreamLinksIfNeeded(stream))
    },
    fetchStreamsIfNeeded: () => {
      dispatch(fetchLiveStreamsIfNeeded())
    },
    flashMessage: (message) => {
      dispatch(flashMessage(message, {push: true, timeout: 5000}))
    },
    setTheaterModeOn: () => {
      dispatch(setTheaterModeOn())
    },
    setTheaterModeOff: () => {
      dispatch(setTheaterModeOff())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StreamFetcher)