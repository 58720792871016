import {Model, fk, attr} from "redux-orm"

export class FeaturedArtist extends Model {
}
FeaturedArtist.modelName = 'FeaturedArtist'

FeaturedArtist.fields = {
  performance: fk('Performance'),
  artist: fk('Person', 'featured_artists'),
  roles: attr()
}