/*eslint no-debugger: "off"*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { google, outlook, ics, office365 } from "calendar-link"
import googleCalendar from '../../../images/icons/google_calendar.svg'
import outlookCalendar from '../../../images/icons/outlook.svg'
import neaLogo from '../../../images/icons/nea_logo.svg'
import msabLogo from '../../../images/icons/msab_logo.png'
import {FaCalendarPlus} from 'react-icons/fa'
import SvgIcon from '../SvgIcon'

import {formatMarkdown, setTitle, formatInline, stripFormatting, logAnalyticEvent, JW_PLAYER_SRC} from '../../util'
import LoadingIndicator from '../LoadingIndicator'
import PageHeader from '../../containers/PageHeaderContainer'
import ImageSelector from '../ImageSelector'
import StreamPlayer from './StreamPlayer'
import Meta from '../Meta'
import moment from 'moment'
import ReactJWPlayer from '../../react-jw-player/react-jw-player'
import ShareButtons from '../ShareButtons'
import fullscreenOn from '../../../images/icons/24/fullscreen-on.svg'
import fullscreenOff from '../../../images/icons/24/fullscreen-off.svg'


class LiveStream extends Component {
  constructor() {
    super()
    this.onCalendarShow = this.onCalendarShow.bind(this)
    this.onReplayClick = this.onReplayClick.bind(this)
    this.logAddToCalendar = this.logAddToCalendar.bind(this)

    this.state = {
      showCalendarLinks: false,
      playing: false
    }
  }

  onReplayClick() {
    if(! this.state.playing) {
      // this.props.pauseAudio()
      this.setState({
        playing: true
      })
    }
  }

  onCalendarShow() {
    this.setState({showCalendarLinks: true})
  }

  logAddToCalendar(kind) {
    logAnalyticEvent('add_to_calendar', {kind: kind})
  }

  render() {
    let {stream, linksFetched, linksFailure, fetchComplete, liveStreamsFetched, linksNotNeeded, flashMessage, theaterMode,  setTheaterModeOn, setTheaterModeOff } = this.props
    let streamDisplay, header, programListing, sponsorListing, programLink, calendarEvent, playerActive

    if(liveStreamsFetched && ! stream) {
      flashMessage("Sorry, that live stream is no longer available.")
      this.context.router.history.push(`/live-streams`)
    }
    else if(! fetchComplete || ! liveStreamsFetched  ||  (! linksFetched && ! linksFailure && ! linksNotNeeded)) {
      streamDisplay = <LoadingIndicator margin="70px" />
    }
    else {
      setTitle(stream.title)
      let player
      const startMoment = stream.startMoment()
      const replayEndMoment = stream.replayEndMoment()
      const now = moment()

      if(linksFailure) {
        const error_message = stream.stream_error_message || 'This livestream can not be played.'
        player = <div className="livestream_note ended">{ error_message }</div>
      }
      else if(stream.isLive()) {
        playerActive = true
        player = <StreamPlayer stream={stream} />
      }
      else if(stream.isComplete() && stream.replayAvailable()) {
        if(this.props.linksFetched) {

          const playerQueue = [{
            file: stream.stream_links.broadband_standard_dynamics,
            title: stripFormatting(stream.title),
            _gaId: `C-${this.props.stream.uuid}`,

          }]
          playerActive = true
          player =
            <div>
              <p>The livestream is over, but you can replay the performance until <strong>{replayEndMoment.format("dddd, MMMM D, [at] h:mm a")}</strong> {stream.replayEndAddtimeZone()}.</p>

            <ReactJWPlayer
                        playerId="live-stream-video-player"
                        isAutoPlay={false}
                        aspectRatio="16:9"
                        playerScript={JW_PLAYER_SRC}
                        file=""
                        gaCategory="Media"
                        gaInterval={true}
                        playlist={playerQueue}
                        muxKey={process.env.REACT_APP_MUX_KEY}
                        muxPlayer="Live Stream"
                        muxType="live"
                        />
          </div>
        }
        else {

          player = (
           <div className="play_buttons">
            </div>
          )
        }
      }
      else if (stream.isComplete()) {
        player = <div className="livestream_note ended">This livestream has ended.</div>
      }
      else if(startMoment > moment().subtract(15, 'minutes') && startMoment < moment().add(4, 'hours')) {
        player =
        <div className="row">
        <div className="shrink columns"> <LoadingIndicator alt={true} margin="40px" /></div>
        <div className="livestream_note soon columns">
          This live stream will start at <strong>{startMoment.format("h:mm a")}</strong>{stream.addTimeZone()}.
          <br />
          <em>No need to refresh your browser — the video will automatically appear shortly before the stream begins.</em>

        </div></div>
      }
      else if(startMoment > now) {
        player = <div className="livestream_note future">This live stream will start <strong>{stream.startDisplay()}</strong></div>
      }
      else { // Failsafe
        player = <div className="livestream_note ended"></div>
      }
      if(startMoment > now || 3 > 1) {
        calendarEvent = {
          title: stripFormatting(stream.title),
          description: stripFormatting(stream.description),
          start: startMoment.format(),
          duration: [stream.program_duration || 180, "minutes"],
        }
      }

      if(stream.media_underwriters.length > 0) {
        const sponsors = stream.media_underwriters.map( (sponsor, index) => {
          let sponsorImage = <img src={sponsor.media_underwriter_logo} title={sponsor.name} />
          if(sponsor.link) {
            // eslint-disable-next-line
            sponsorImage = <a href={sponsor.link} target="_blank" rel="noopener">{sponsorImage}</a>
          }
          return (
            <li key={`sponsor-${index}`}>{sponsorImage}</li>
          )
        })

        sponsorListing = <div className="stream_sponsors underwriter_logo">
        <p>Underwriting Support Provided By</p>
          <ul className="sponsors underwriter_logo">{ sponsors }</ul>
        </div>
      }
      const path = `/live-stream/${stream.identifier}`
      const fbMessage = "Check out this live concert broadcast of The Saint Paul Chamber Orchestra! You can tune in for free."
      const twMessage = "Check out this @thespco live concert broadcast! #NowStreaming for #Free"
      const nativeMessage = 'Check out this free SPCO live concert broadcast!'

      header = (
          <div className="row">
            <div className="image columns medium-4 small-12">
              <ImageSelector imageList={stream.preferred_image} size="wd_512x288" />
            </div>
            <div className="text columns medium-8 small-12">
              <h1><span dangerouslySetInnerHTML={{__html: formatInline(stream.title)}} /></h1>
              <div className="program_note long_description">
                <div dangerouslySetInnerHTML={{__html: formatMarkdown(stream.description)}} />
              </div>
              <ShareButtons path={path} fb={fbMessage} tw={twMessage} native={nativeMessage} />
            </div>
          </div>
        )

      if(stream.program) {
        programListing = stream.program.composition_performances.map( (performance, index) => {
          const compositionLink = <strong dangerouslySetInnerHTML={{__html: formatInline(performance.composition.title)}} />
          const premiere = performance.premiere ? <span className="premiere">{ performance.premiere } Premiere</span> : null
          const commission = performance.composition.spco_commission ? <span className="commission">SPCO Commissioned</span>: null
          let conductor
          if(performance.conductor_instance) {
            conductor = <div className="artist conductor">
                  <em>{performance.conductor_instance.first_name} {performance.conductor_instance.last_name}</em>
                  <span>conductor</span>
                </div>
          }
          const artists = performance.featured_artists_instances ? performance.featured_artists_instances.map( (artist) => {
            const role = artist.roles ? <span>{artist.roles}</span> : null
            return (
                <div className="artist" key={artist.person.id}>
                  <em>{artist.person.first_name} {artist.person.last_name}</em>
                  {role}
                </div>
            )
          }) : null
          return (
          <div className="program_performance stream" key={`perf-${index}`}>
              <h3>
                {compositionLink}
                <span>{performance.composer.first_name} {performance.composer.last_name}</span>
                {premiere}
                {commission}
              </h3>
              {conductor}
              {artists}
          </div>
          )
        })
        if(stream.program.identifier && stream.show_link_to_program) {
          let url = stream.program.print_program_url ? stream.program.print_program_url : `${process.env.REACT_APP_PROGRAM_HOST}/${stream.program.identifier}`
          // eslint-disable-next-line
          programLink = <a className="button" href={url} target="_blank">View/Print Concert Program</a>
        }

      }

      const wrapperClasses = classnames(
        {
          composition_performance: true,
          live_stream_wrapper: true,
          row: true,
          collapse: true,
          theater_mode: theaterMode
        }
      )

      streamDisplay = (
        <div className={wrapperClasses}>
          <div className="sidebar columns medium-4 small-12">
          </div>
          <div className="main_container live_stream columns medium-8 small-12">
            <Meta title={stripFormatting(stream.title)} image={stream.preferred_image} description={stripFormatting(stream.description)} />
            <div className="live_stream_player">
              {player}
            </div>
            <div className="row">
            { programLink && (
                <div className="columns print_link">
                {programLink}
              </div>
              )}

            {calendarEvent && ! this.state.showCalendarLinks &&
            <div className="columns shrink ical_link">
              <a className="button light" onClick={this.onCalendarShow}>Add to Calendar...</a>
            </div>
            }
            { playerActive && (
              <div className="columns shrink theater_mode show-for-medium">
                {theaterMode && (<a className="button light" onClick={setTheaterModeOff}><SvgIcon title="Normal video size" icon={fullscreenOff} fill="#222222" /> Normal view</a>)}
                {!theaterMode && (<a className="button light" onClick={setTheaterModeOn}><SvgIcon title="Theater mode" icon={fullscreenOn} fill="#222222" />Theater mode</a>)}
              </div>
            )}


              {this.state.showCalendarLinks &&
              <div className="columns small-12 ical_link calendar_links">
                <a className="button light" href={google(calendarEvent)} rel="noopener noreferrer" target="_blank" onClick={() => this.logAddToCalendar('google')}><SvgIcon icon={googleCalendar} fill="#b5b5b5" />Add to Google Calendar</a>
                <a className="button light" href={outlook(calendarEvent)} rel="noopener noreferrer" target="_blank" onClick={() => this.logAddToCalendar('outlook')}><SvgIcon icon={outlookCalendar} /> Add to Outlook</a>
                <a className="button light" href={office365(calendarEvent)} rel="noopener noreferrer" target="_blank" onClick={() => this.logAddToCalendar('office365')}><SvgIcon icon={outlookCalendar} /> Add to Outlook/Office 365</a>
                <a className="button light" href={ics(calendarEvent)} rel="noopener noreferrer" target="_blank" onClick={() => this.logAddToCalendar('ical')}><FaCalendarPlus className="ical"/> Add to Calendar with iCal</a>
              </div>
              }
            </div>

            { stream.isLive() &&stream.chat_code && <iframe className="chat" src={'https://go.arena.im/embed/chat/the-saint-paul-chamber-orchestra/' + stream.chat_code}></iframe>}
            <div className="stream_program_wrap">
              {programListing}
            </div>

          <div className="row extra_sponsors">
            <div className="columns small-12 medium-6 nea_sponsor"><div className="row">
              <div className="columns shrink"><SvgIcon icon={neaLogo} alt="NEA Logo" /></div>
              <div className="columns"><p>The Saint Paul Chamber Orchestra is supported in part by an American Rescue Plan Act grant from the National Endowment for the Arts to support general operating expenses in response to the COVID-19 pandemic.</p></div>
            </div></div>
            <div className="columns small-12 medium-6 msab_sponsor"><div className="row">
              <div className="columns shrink"><img src={msabLogo} alt="MSAB Logo"/></div>
              <div className="columns"><p>This activity is made possible by the voters of Minnesota through a Minnesota State Arts Board Operating Support grant, thanks to a legislative appropriation from the arts and cultural heritage fund.</p></div>
            </div></div>
          </div>


          <div className="row">
            <div className="columns">{ sponsorListing }</div>
          </div>
          { /*
          <div className="row align-middle">
            <div className="contribute_block columns">
              <div className="row">
                <div className="columns align-self-middle">
                  <h5>How Was Your Experience?</h5>
                  <p>We’re collecting feedback about these livestreams via a short survey.</p>
                </div>
                <div className="columns shrink align-self-middle"><a className="button" href="https://www.surveymonkey.com/r/THRR6MH">Complete the Survey</a></div>
              </div>
            </div>
          </div>
          */}

          </div>
        </div>
      )


    }

    return (
      <div>
        <PageHeader section="live_streams">
          <div className="row composition_show">
            <div className="columns small-12">
              <header className="composition">
                <div className="page_label">Live Stream</div>
                {header}
              </header>
            </div>
          </div>
        </PageHeader>
        <main>
          <div className="row">
            <div className="columns">
              {streamDisplay}
            </div>
          </div>
        </main>
      </div>
    )
  }

}

// let LiveStream = ({stream, linksFetched, linksFailure, fetchComplete, liveStreamsFetched, flashMessage }, context) => {
//
// }

LiveStream.propTypes = {
  stream: PropTypes.object,
  linksFetched: PropTypes.bool,
  fetchComplete: PropTypes.bool,
  liveStreamsFetched: PropTypes.bool,
  linksNotNeeded: PropTypes.bool,
  linksFailure: PropTypes.string,
  flashMessage: PropTypes.func,
  theaterMode: PropTypes.bool,
  setTheaterModeOn: PropTypes.func,
  setTheaterModeOff: PropTypes.func
}
LiveStream.contextTypes = {
  router: PropTypes.object
}

export default LiveStream